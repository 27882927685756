@import url("https://cdn.jsdelivr.net/npm/galmuri/dist/galmuri.css");

.input-style {
  font-family: "Galmuri11", sans-serif;
  font-weight: bold;
  font-size: large;
  background-image: url("../assets/start/SquareButton.png");
  background-size: contain;
  background-color: transparent;
  background-size: cover;
  aspect-ratio: 175/ 60;
  width: 270px;
  border: none;
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  position: relative;
  margin-bottom: 10px;
}
